import styled from "styled-components"

export default styled.section`
  width: 80%;
  max-width: 1000px;
  margin: 7rem auto 5rem auto;
  color: var(--red);

  h1,h2,h3,h4,h5,h6 {
    color: var(--red);
  }
`