import styled from "styled-components"

export default styled.section.attrs({ className: "calculator-section" })`
  margin: 5rem 0;
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    max-width: 1000px;

    @media only screen and (max-width: 976px) {
      grid-template-columns: 1fr;
      width: 90%;
    }
  }
`