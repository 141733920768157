import React from "react"
import MortgageRepaymentSEO from "../../components/calculators/mortgage-repayment/mortgageRepaymentSEO"
import CalculatorsTabs from "../../components/calculators/calculatorsTabs"
import MortgageRepaymentCalculator from "../../components/calculators/mortgage-repayment/calculator"
import AboutCalculator from "../../components/calculators/mortgage-repayment/aboutCalculator"
import WhatAndHow from "../../components/calculators/mortgage-repayment/whatAndHow"
import TextAndButton from "../../components/calculators/mortgage-repayment/textAndButton"
import BlogsSection from "../../components/shared/blogsSection/"
import { Query } from "@graphql-types"
import { colors } from "@util/constants"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "@global"

const MortgageRepayment = () => {
  const {
    sanityCalculatorMortgageRepaymentPage,
  }: Query = useStaticQuery(graphql`
    query {
      sanityCalculatorMortgageRepaymentPage {
        title
        categories {
          colorList
        }
      }
    }
  `)

  if (sanityCalculatorMortgageRepaymentPage == null) return null
  const { title, categories } = sanityCalculatorMortgageRepaymentPage

  const categoryColor = categories && categories[0] && categories[0].colorList

  const headerData = {
    headerColor: categoryColor ?? colors.maroon,
  }

  return (
    <div className="subpage-container">
      <MortgageRepaymentSEO />
      <Header title={title} headerData={headerData} />
      <CalculatorsTabs />
      <AboutCalculator />
      <MortgageRepaymentCalculator />
      <WhatAndHow />
      <TextAndButton />
      <BlogsSection title="Blogs" />
    </div>
  )
}

export default MortgageRepayment
