import React, { useState } from "react";
import { Slider, InputNumber, Select } from "antd";
import InputsBlock from "./inputsStyle";
import styled from "styled-components";
import { anyValueToNumber, formatValue, parseValue } from "@util/helpers";
import { useAnonomousLogin, useSetDoc } from "@lib/firebase";
import { useGetIP } from "@util/hooks";
import { Button, GiveMeSomeSpace } from "@util/standard";
import { pushMortgageCalculatorData } from "@util/dataLayer";

const { Option } = Select;

const HoverContainer = styled.span`
  display: inline-flex;
  flex-direction: row;
  background-color: var(--red);
  color: white;
  padding: 1px 8px;
  margin-left: 10px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const InfoPopUp = styled.div`
  position: absolute;
  color: black;
  background-color: var(--white);
  top: 0px;
  width: 275px;
  right: -275px;
  z-index: 2;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 5px 6px 18px 2px rgba(0, 0, 0, 0.33);
`;

const MobileContainer = styled.div`
  @media only screen and (min-width: 600px) {
    display: none;
  }
  display: block;
  color: black;
  a {
    color: var(--red);
  }
`;
interface Props {
  inputs: {
    mortgage_size: number;
    interest_rate: number;
    mortgage_terms: number;
    payment_frequency: string;
    should_show: boolean;
  };
  setInputs: any;
}

const Inputs: React.FC<Props> = ({ inputs, setInputs }) => {
  const [hoverPopUpOn, setHoverPopUpOn] = useState(false);

  const ip = useGetIP();
  useAnonomousLogin();

  const handleClick = () => {
    setInputs({
      ...inputs,
      should_show: true,
    });

    const data = {
      loanAmount: inputs.mortgage_size,
      interestRate: inputs.interest_rate,
    };

    pushMortgageCalculatorData(data);

    useSetDoc("mortgage-repayment", {
      mortgageSize: anyValueToNumber(inputs.mortgage_size),
      interestRate: anyValueToNumber(inputs.interest_rate),
      mortgageTerms: anyValueToNumber(inputs.mortgage_terms),
      paymentFrequency: inputs.payment_frequency ?? "",
      userAgent: window.navigator.userAgent,
      ipAddress: ip.ip,
      country: ip.country,
    });
  };

  return (
    <InputsBlock>
      <label htmlFor="mortgage_size">Loan Amount</label>
      <input
        style={{ width: "100%" }}
        name="mortgage_size"
        value={formatValue(inputs.mortgage_size)}
        onChange={value => {
          setInputs({
            ...inputs,
            mortgage_size: parseValue(value.target.value),
          });
        }}
      />

      <label htmlFor="interest_rate">
        Interest Rate
        <HoverContainer
          onMouseEnter={() => setHoverPopUpOn(true)}
          onMouseLeave={() => setHoverPopUpOn(false)}
        >
          ?
          {hoverPopUpOn && (
            <InfoPopUp>
              <p>For information on current interest rates, please visit:</p>
              <a href="https://www.interest.co.nz/borrowing" target="_blank">
                https://www.interest.co.nz/borrowing
              </a>
            </InfoPopUp>
          )}
        </HoverContainer>
        <MobileContainer>
          For information on current interest rates,
          <a target="_blank" href="https://www.interest.co.nz/borrowing">
            click here
          </a>
        </MobileContainer>
      </label>

      <div className="row">
        <Slider
          min={0}
          max={10}
          marks={{
            0: "0",
            2: "2",
            4: "4",
            6: "6",
            8: "8",
            10: "10",
          }}
          step={0.01}
          value={inputs.interest_rate}
          onChange={value => {
            setInputs({ ...inputs, interest_rate: value });
          }}
        />
        <InputNumber
          value={inputs.interest_rate}
          formatter={value => `${value}%`}
          parser={value => value?.replace("%", "")?.replace(/\s/g, "")}
          step={0.01}
          max={10}
          min={0}
          onChange={value => {
            setInputs({ ...inputs, interest_rate: value });
          }}
        />
      </div>

      <label htmlFor="mortgage_terms">Loan Term</label>
      <div className="row">
        <Slider
          min={1}
          max={30}
          marks={{ 1: "1 yr", 30: "30 yrs" }}
          step={1}
          value={inputs.mortgage_terms}
          onChange={value => {
            setInputs({ ...inputs, mortgage_terms: value });
          }}
        />
        <InputNumber
          formatter={value => `${value} Yrs`}
          parser={value => value.replace("Yrs", "").replace(/\s/g, "")}
          value={inputs.mortgage_terms}
          min={1}
          max={30}
          onChange={value => {
            setInputs({ ...inputs, mortgage_terms: value });
          }}
        />
      </div>

      <label htmlFor="payment_frequency">Payment Frequency</label>
      <Select
        value={inputs.payment_frequency}
        onChange={value => setInputs({ ...inputs, payment_frequency: value })}
        style={{ width: "100%" }}
      >
        <Option value="weekly">Weekly</Option>
        <Option value="fortnightly">Fortnightly</Option>
        <Option value="monthly">Monthly</Option>
      </Select>
      <GiveMeSomeSpace space={10} />
      <Button onClick={handleClick}>Calculate repayment amount</Button>
    </InputsBlock>
  );
};

export default Inputs;
