import React, { useState } from "react";
import Inputs from "./inputs";
import Results from "./results";
import CalculatorSection from "./calculatorStyle";

const MortgageRepaymentCalculator = () => {
  const [inputs, setInputs] = useState({
    mortgage_size: 0,
    interest_rate: 7,
    mortgage_terms: 30,
    payment_frequency: "weekly",
    should_show: false,
  });

  return (
    <CalculatorSection>
      <div className="container">
        <Inputs inputs={inputs} setInputs={setInputs} />
        <Results inputs={inputs} />
      </div>
    </CalculatorSection>
  );
};

export default MortgageRepaymentCalculator;
