import React, { useEffect } from "react";
import ResultsBlock from "./resultsStyle";
import { pushMortgageCalculatorData } from "@util/dataLayer";

interface Props {
  inputs: {
    mortgage_size: number;
    interest_rate: number;
    mortgage_terms: number;
    payment_frequency: string;
    should_show: boolean;
  };
}

const Results: React.FC<Props> = ({ inputs }) => {
  const pmt = (rate_per_period, number_of_payments, present_value, future_value = 0, type = 0) => {
    future_value = typeof future_value !== "undefined" ? future_value : 0;
    type = typeof type !== "undefined" ? type : 0;
    if (rate_per_period != 0.0) {
      // Interest rate exists
      var q = Math.pow(1 + rate_per_period, number_of_payments);
      return (
        -(rate_per_period * (future_value + q * present_value)) /
        ((-1 + q) * (1 + rate_per_period * type))
      );
    } else if (number_of_payments != 0.0) {
      // No interest rate, but number of payments exists
      return -(future_value + present_value) / number_of_payments;
    }
    return 0;
  };

  const decimalFix = num => (Math.round(num * 100) / 100).toFixed(2);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const monthlyPayment =
    pmt(inputs.interest_rate / 100 / 12, inputs.mortgage_terms * 12, inputs.mortgage_size) * -1;

  const weeklyPayment =
    pmt(inputs.interest_rate / 100 / 52, inputs.mortgage_terms * 52, inputs.mortgage_size) * -1;
  const fortnightlyPayment =
    pmt(inputs.interest_rate / 100 / 27, inputs.mortgage_terms * 27, inputs.mortgage_size) * -1;

  const totalPayment =
    inputs.payment_frequency === "monthly"
      ? monthlyPayment * (inputs.mortgage_terms * 12)
      : inputs.payment_frequency === "weekly"
      ? weeklyPayment * (inputs.mortgage_terms * 52)
      : fortnightlyPayment * (inputs.mortgage_terms * 27);

  const interestCost = totalPayment - inputs.mortgage_size;
  const principalPayment = (inputs.mortgage_size / totalPayment) * 100;
  const interestPayment = (interestCost / totalPayment) * 100;

  const weeklyInterest = weeklyPayment * (inputs.mortgage_terms * 52) - inputs.mortgage_size;
  const fortnightlyInterest =
    fortnightlyPayment * (inputs.mortgage_terms * 27) - inputs.mortgage_size;
  const monthlyInterest = monthlyPayment * (inputs.mortgage_terms * 12) - inputs.mortgage_size;

  return (
    <ResultsBlock>
      {inputs.should_show ? (
        <>
          <h3>Results</h3>

          <ul>
            {inputs.payment_frequency === "weekly" && (
              <li>
                <span className="label">Your Weekly Payment</span>
                <span className="result">
                  {formatter.format(parseFloat(decimalFix(weeklyPayment)))}
                </span>
              </li>
            )}
            {inputs.payment_frequency === "fortnightly" && (
              <li>
                <span className="label">Your Fortnightly Payment</span>
                <span className="result">
                  {formatter.format(parseFloat(decimalFix(weeklyPayment * 2)))}
                </span>
              </li>
            )}
            {inputs.payment_frequency === "monthly" && (
              <li>
                <span className="label">Your Monthly Payment</span>
                <span className="result">
                  {formatter.format(parseFloat(decimalFix(monthlyPayment)))}
                </span>
              </li>
            )}
            <li>
              <span className="label">Total Payments</span>
              <span className="result">
                {formatter.format(parseFloat(decimalFix(totalPayment)))}
              </span>
            </li>
            <li>
              <span className="label">Interest Costs</span>
              <span className="result">
                {inputs.payment_frequency === "weekly"
                  ? formatter.format(parseFloat(decimalFix(weeklyInterest)))
                  : inputs.payment_frequency === "fortnightly"
                  ? formatter.format(parseFloat(decimalFix(fortnightlyInterest)))
                  : inputs.payment_frequency === "monthly"
                  ? formatter.format(parseFloat(decimalFix(monthlyInterest)))
                  : ""}
              </span>
            </li>
            <li>
              <span className="label">Principal Payments</span>
              <span className="result">
                {decimalFix(principalPayment) === "NaN" ? "-" : decimalFix(principalPayment)}%
              </span>
            </li>
            <li>
              <span className="label">Interest Payments</span>
              <span className="result">
                {decimalFix(interestPayment) === "NaN" ? "-" : decimalFix(interestPayment)}%
              </span>
            </li>
          </ul>
        </>
      ) : (
        <>
          <h3>Click calculate to display your results</h3>
        </>
      )}
    </ResultsBlock>
  );
};

export default Results;
